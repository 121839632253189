<template>
  <button
    class="flex justify-center mx-auto text-center rounded-3xl py-1 px-4 bg-banyen border-2 border-black"
    v-bind="$attrs"
  >
    <slot />
  </button>
</template>

<style lang="scss" scoped>
button:disabled {
  @apply cursor-not-allowed opacity-50;
}
</style>
